.tasks {
    padding: var(--p-xl) 0;

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: var(--p-m);
        padding: var(--p-m) 0;

        @include respond(small) {
            display: flex;
            flex-direction: column;
        }

        a {
            @include respond(small) {
                width: 80%;
                align-self: center;
            }
        }
    }
}