.display-1 {
    font-family: $family-primary;
    font-weight: $font-bold;
    font-size: var(--font-size-42);
    line-height: calc(var(--font-size-42) * 1.5);
    text-decoration: none;
    color: var(--text-primary);
}

.display-2 {
    font-family: $family-secondary;
    font-weight: $font-bold;
    font-size: var(--font-size-42);
    line-height: calc(var(--font-size-42) * 1.2);
    text-decoration: none;
    color: var(--text-primary);
    margin-bottom: 1rem !important;

    @include respond(large) {
        --font-size-42: 3rem;
    }

    @include respond(medium) {
        --font-size-42: 2.4rem;
    }
}

.display-3 {
    font-family: $family-secondary;
    font-weight: $font-regular;
    font-size: var(--font-size-20);
    line-height: calc(var(--font-size-20) * 1.5);
    text-decoration: none;
    color: var(--text-primary);
}

.heading-1 {
    font-family: $family-primary;
    font-weight: $font-bold;
    font-size: var(--font-size-48);
    line-height: calc(var(--font-size-48) * 1.125);
    text-decoration: none;
    color: var(--text-primary);
    margin-bottom: var(--p-m);
}

.heading-2 {
    font-family: $family-primary;
    font-weight: $font-bold;
    font-size: var(--font-size-20);
    line-height: calc(var(--font-size-20) * 1.5);
    text-decoration: none;
    color: var(--text-primary);
    margin-bottom: var(--p-m);
}

.detail {
    font-family: $family-primary;
    font-weight: $font-bold;
    font-size: var(--font-size-16);
    line-height: calc(var(--font-size-16) * 1.05);
    text-decoration: none;
    color: var(--text-primary);
}

.body,
p {
    font-family: $family-secondary;
    font-weight: $font-regular;
    font-size: var(--font-size-18);
    line-height: calc(var(--font-size-18) * 1.5);
    color: var(--text-primary);

    &:not(:last-of-type) {
        margin-bottom: 1em;
    }
}