.dates {
    padding: var(--p-xl) 0;
    overflow: hidden;

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: var(--p-m);
        padding-top: var(--p-xl);

        @include respond(small) {
            grid-template-columns: 1fr;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: var(--p-lg) var(--p-sm);
        position: relative;
        margin-bottom: var(--p-lg);
        
        
        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border: 2px solid var(--primary);
            transform: skew(-20deg);
            z-index: -1;

            @include respond(small) {
                width: 80%;
            }
        }

        &.active {
            &:before {
                background-color: var(--primary);
            }
        }

        span {
            position: absolute;
            top: -2.5rem;
            @extend .display-2;
            color: var(--text-secondary);
            z-index: 2;
            
            &:before {
                content: "";
                display: block;
                position: absolute;
                background-color: var(--secondary);
                border-radius: 50%;
                width: 8rem;
                height: 8rem;
                left: calc(50% - 4rem);
                top: calc(50% - 4rem);
                z-index: -1;
            }
        }

        a {
            margin-top: 2rem;
        }
    }
}