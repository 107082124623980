html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("jetbrains-mono-v13-latin-ext_latin-regular.96be835c.woff2") format("woff2"), url("jetbrains-mono-v13-latin-ext_latin-regular.51443629.woff") format("woff");
}

@font-face {
  font-family: JetBrains Mono;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("jetbrains-mono-v13-latin-ext_latin-700.365f1028.woff2") format("woff2"), url("jetbrains-mono-v13-latin-ext_latin-700.6f60ce81.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("inter-v12-latin-ext_latin-regular.4ac308a8.woff2") format("woff2"), url("inter-v12-latin-ext_latin-regular.39ec15da.woff") format("woff");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("inter-v12-latin-ext_latin-700.1314b6bb.woff2") format("woff2"), url("inter-v12-latin-ext_latin-700.c399424f.woff") format("woff");
}

:root {
  --primary: #ffcb08;
  --primary-hover: #ffe417;
  --secondary: #009fe3;
  --secondary-hover: #00b3ff;
  --text-primary: #1a1a1a;
  --text-secondary: #fff;
  --bg-light: #f2f2f2;
  --bg-dark: #1a1a1a;
  --bg-white: #fff;
  --font-size: 62.5%;
  --font-size-48: 4.8rem;
  --font-size-42: 4.2rem;
  --font-size-40: 3.8rem;
  --font-size-20: 2rem;
  --font-size-18: 1.8rem;
  --font-size-12: 1.2rem;
  --p-sm: 2rem;
  --p-m: 6rem;
  --p-lg: 8rem;
  --p-xl: 10rem;
  --p-xxl: 11rem;
}

@media only screen and (max-width: 48em) {
  :root {
    --font-size-48: 3.6rem;
    --font-size-42: 3.2rem;
    --font-size-40: 3rem;
    --font-size-20: 2rem;
    --font-size-18: 1.8rem;
    --font-size-12: 1.2rem;
  }
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  font-size: var(--font-size);
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-white);
  color: var(--text-primary);
  box-sizing: border-box;
  font-size: 1.8rem;
  position: relative;
  overflow-x: hidden;
}

a:focus, button:focus, input:focus, select:focus, textarea:focus {
  box-shadow: 0px 0px 0px 10px var(--secondary);
  outline: none;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

body:not(.user-is-tabbing) a:focus, body:not(.user-is-tabbing) button:focus, body:not(.user-is-tabbing) input:focus, body:not(.user-is-tabbing) select:focus, body:not(.user-is-tabbing) textarea:focus {
  box-shadow: none;
  outline: none;
}

.display-1 {
  font-family: JetBrains Mono, monospace;
  font-weight: 700;
  font-size: var(--font-size-42);
  line-height: calc(var(--font-size-42) * 1.5);
  color: var(--text-primary);
  text-decoration: none;
}

.display-2, .dates__item span {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: var(--font-size-42);
  line-height: calc(var(--font-size-42) * 1.2);
  color: var(--text-primary);
  text-decoration: none;
  margin-bottom: 1rem !important;
}

@media only screen and (max-width: 75em) {
  .display-2, .dates__item span {
    --font-size-42: 3rem;
  }
}

@media only screen and (max-width: 62em) {
  .display-2, .dates__item span {
    --font-size-42: 2.4rem;
  }
}

.display-3 {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: var(--font-size-20);
  line-height: calc(var(--font-size-20) * 1.5);
  color: var(--text-primary);
  text-decoration: none;
}

.heading-1 {
  font-family: JetBrains Mono, monospace;
  font-weight: 700;
  font-size: var(--font-size-48);
  line-height: calc(var(--font-size-48) * 1.125);
  color: var(--text-primary);
  margin-bottom: var(--p-m);
  text-decoration: none;
}

.heading-2 {
  font-family: JetBrains Mono, monospace;
  font-weight: 700;
  font-size: var(--font-size-20);
  line-height: calc(var(--font-size-20) * 1.5);
  color: var(--text-primary);
  margin-bottom: var(--p-m);
  text-decoration: none;
}

.detail, .nav__item {
  font-family: JetBrains Mono, monospace;
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: calc(var(--font-size-16) * 1.05);
  color: var(--text-primary);
  text-decoration: none;
}

.body, p {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: var(--font-size-18);
  line-height: calc(var(--font-size-18) * 1.5);
  color: var(--text-primary);
}

.body:not(:last-of-type), p:not(:last-of-type) {
  margin-bottom: 1em;
}

@media only screen and (max-width: 62em) {
  .nav {
    width: 100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 80;
    background: rgba(95, 105, 116, .2);
    transition: opacity .35s, visibility .35s;
    position: fixed;
    top: 0;
    left: 0;
  }

  .nav.is-open {
    opacity: 1;
    visibility: visible;
    height: 100%;
  }

  .nav.is-open > .nav__list {
    height: 100%;
  }
}

.nav__list {
  flex-direction: row;
  gap: 2em;
  list-style-type: none;
  display: flex;
}

@media only screen and (max-width: 62em) {
  .nav__list {
    height: 0;
    width: 100%;
    background-color: var(--bg-light);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    transition: height .35s;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.nav__item {
  z-index: 5;
  position: relative;
}

.nav__item:before, .nav__item:after {
  content: "";
  width: 110%;
  height: 10px;
  background-color: var(--primary);
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  z-index: -1;
  transition: -webkit-clip-path .35s ease-in, clip-path .35s ease-in;
  display: block;
  position: absolute;
  bottom: 0;
  left: -5%;
  transform: skew(-20deg);
}

.nav__item:after {
  background-color: var(--primary-hover);
}

.nav__item.active:before, .nav__item:hover:after {
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
}

.nav__button {
  height: 4.4rem;
  width: 4.4rem;
  cursor: pointer;
  z-index: 100;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  display: none;
  position: relative;
}

@media only screen and (max-width: 62em) {
  .nav__button {
    display: flex;
  }
}

.nav__button--burger {
  width: 4.4rem;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.nav__button--burger i {
  background: var(--primary);
  height: 3px;
  width: 3rem;
  border: none;
  transition: transform .35s;
  position: absolute;
  top: 1rem;
  left: 0;
}

.nav__button--burger i:nth-of-type(2) {
  top: 2rem;
}

.nav__button--burger i:nth-of-type(3) {
  top: 3rem;
}

.nav__button:hover i {
  background: var(--secondary);
}

.nav__button.is-activated .nav__button--top {
  transform: translateY(1rem)translateX(0)rotate(45deg);
}

.nav__button.is-activated .nav__button--middle {
  opacity: 0;
}

.nav__button.is-activated .nav__button--bottom {
  transform: translateY(-1rem)translateX(0)rotate(-45deg);
}

.btn {
  font-family: JetBrains Mono, monospace;
  font-weight: 700;
  font-size: var(--font-size-20);
  line-height: calc(var(--font-size-20) * 1.05);
  color: var(--text-primary);
  text-transform: uppercase;
  word-spacing: 1px;
  padding: var(--p-sm);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 48em) {
  .btn {
    font-size: 1.4rem;
  }
}

.btn:before, .btn:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: transform .2s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(-20deg);
}

.btn:after {
  z-index: -2;
}

.btn-primary:before {
  background-color: var(--primary);
}

.btn-primary:after {
  background-color: var(--secondary);
}

.btn-primary:hover:before {
  background-color: var(--primary-hover);
}

.btn-primary:hover:after {
  transform: skew(-20deg)translate(5px, 5px);
}

.btn-secondary:before {
  background-color: var(--secondary);
}

.btn-secondary:after {
  background-color: var(--bg-white);
}

.btn-secondary:hover:before {
  background-color: var(--secondary-hover);
}

.btn-secondary:hover:after {
  transform: skew(-20deg)translate(10px, 8px);
}

.btn:disabled {
  cursor: not-allowed;
}

@media only screen and (max-width: 48em) {
  .btn:disabled {
    width: 80%;
    margin: 4rem auto 0;
  }
}

.btn:disabled:before {
  background-color: #c2c2c2;
}

.btn:disabled:after {
  content: none;
}

.link {
  font-family: JetBrains Mono, monospace;
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: calc(var(--font-size-16) * 2);
  color: var(--text-primary);
  word-spacing: 1px;
  text-decoration: none;
  display: inline-block;
}

.link__highlight {
  z-index: 1;
  position: relative;
}

.link__highlight:before, .link__highlight:after {
  content: "";
  width: 100%;
  height: 10px;
  background-color: var(--primary);
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: skew(-20deg);
}

.link__highlight:after {
  background-color: var(--primary-hover);
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  transition: -webkit-clip-path .35s ease-in, clip-path .35s ease-in;
}

.link__highlight:hover:after {
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
}

.link__highlight--white:before {
  background-color: var(--bg-white);
}

.link__highlight--white:after {
  background-color: var(--secondary);
}

.link__download {
  position: relative;
}

.link__download:before {
  content: "";
  width: 26px;
  height: 21px;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.293 15.707a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0-1.414-1.414L13 13.586 7.343 7.929A1 1 0 0 0 5.93 9.343l6.364 6.364ZM12 0v15h2V0h-2Z' fill='%23061214'/%3E%3Cpath stroke='%23061214' stroke-width='2' d='M0 20h26'/%3E%3C/svg%3E");
  position: absolute;
  left: calc(100% + 2rem);
}

.carousel__item {
  width: 25%;
  height: 16.5rem;
  padding: var(--p-sm);
  justify-content: center;
  display: flex;
}

@media only screen and (max-width: 62em) {
  .carousel__item {
    width: 33%;
  }
}

@media only screen and (max-width: 48em) {
  .carousel__item {
    width: 100%;
  }
}

.carousel__item a {
  max-width: 30rem;
  height: 100%;
  display: block;
}

.carousel__item a:hover img {
  transform: scale(1.1);
}

.carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  transition: transform .35s ease-in-out;
}

.carousel__item_pictures {
  width: 45%;
  height: 22.5rem;
  padding: var(--p-sm);
  justify-content: center;
  display: flex;
}

@media only screen and (max-width: 62em) {
  .carousel__item_pictures {
    width: 33%;
  }
}

@media only screen and (max-width: 48em) {
  .carousel__item_pictures {
    width: 100%;
  }
}

.carousel__item_pictures a {
  max-width: 30rem;
  height: 100%;
  display: block;
}

.carousel__item_pictures a:hover img {
  transform: scale(1.1);
}

.carousel__item_pictures img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  transition: transform .35s ease-in-out;
}

.header {
  width: 100%;
  height: 9rem;
  background-color: var(--bg-white);
  z-index: 100;
  transition: height .3s ease-in-out;
  position: fixed;
  top: 0;
}

.header__container {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__logo {
  margin: 0;
}

.header__logo svg {
  width: 292px;
  height: auto;
}

@media only screen and (max-width: 48em) {
  .header__logo svg {
    width: 20rem;
  }
}

.header.scrolled-down {
  height: 6.5rem;
  box-shadow: 0 8px 24px rgba(149, 157, 165, .2);
}

.hero {
  background-color: var(--bg-light);
  margin-bottom: var(--p-xxl);
  padding-top: calc(15vh + 9rem);
  position: relative;
}

@media only screen and (max-width: 62em) {
  .hero {
    padding-top: var(--p-m);
  }
}

.hero:before {
  content: "";
  width: 100%;
  height: 120px;
  background-color: var(--bg-light);
  -webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
}

.hero__container {
  display: flex;
}

@media only screen and (max-width: 62em) {
  .hero__container {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.hero__text {
  flex: 1;
}

@media only screen and (max-width: 62em) {
  .hero__text {
    margin: var(--p-m) 0;
  }
}

.hero__text span.edition {
  font-size: 1.5em;
}

.hero__text span.highlight {
  z-index: 1;
  position: relative;
}

.hero__text span.highlight:before {
  content: "";
  width: 100%;
  height: 2.5rem;
  background-color: var(--primary);
  z-index: -1;
  display: block;
  position: absolute;
  bottom: 0;
  left: -3%;
  transform: skew(-20deg);
}

@media only screen and (max-width: 62em) {
  .hero__text .display-1 {
    text-align: center;
  }
}

.hero__text .display-3 {
  padding-right: var(--p-lg);
}

@media only screen and (max-width: 62em) {
  .hero__text .display-3 {
    text-align: center;
    padding-right: 0;
  }
}

.hero__image {
  width: 535px;
  margin-top: -3rem;
  margin-right: -5rem;
}

@media only screen and (max-width: 62em) {
  .hero__image {
    width: 100%;
    height: auto;
    padding-top: var(--p-lg);
    margin: 0;
  }
}

.hero__image svg {
  width: 100%;
  height: auto;
  transform-box: fill-box;
}

@media only screen and (max-width: 62em) {
  .hero__image svg {
    max-height: 50rem;
  }
}

.hero__image svg g {
  transform-origin: 50% 45%;
}

.dates {
  padding: var(--p-xl) 0;
  overflow: hidden;
}

.dates__container {
  gap: var(--p-m);
  padding-top: var(--p-xl);
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

@media only screen and (max-width: 48em) {
  .dates__container {
    grid-template-columns: 1fr;
  }
}

.dates__item {
  padding: var(--p-lg) var(--p-sm);
  margin-bottom: var(--p-lg);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.dates__item:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid var(--primary);
  z-index: -1;
  display: block;
  position: absolute;
  transform: skew(-20deg);
}

@media only screen and (max-width: 48em) {
  .dates__item:before {
    width: 80%;
  }
}

.dates__item.active:before {
  background-color: var(--primary);
}

.dates__item span {
  color: var(--text-secondary);
  z-index: 2;
  position: absolute;
  top: -2.5rem;
}

.dates__item span:before {
  content: "";
  background-color: var(--secondary);
  width: 8rem;
  height: 8rem;
  z-index: -1;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: calc(50% - 4rem);
  left: calc(50% - 4rem);
}

.dates__item a {
  margin-top: 2rem;
}

.registration {
  background-color: var(--primary);
  padding: var(--p-xl) 0;
}

.registration__container {
  padding-bottom: var(--p-m);
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

@media only screen and (max-width: 48em) {
  .registration__container {
    flex-direction: column;
    align-items: flex-start;
  }
}

.registration__container div {
  padding-right: var(--p-m);
  flex: 1;
}

@media only screen and (max-width: 48em) {
  .registration__container div {
    padding: 0;
  }
}

.registration__container a {
  flex-basis: 41rem;
}

@media only screen and (max-width: 48em) {
  .registration__container a {
    width: 80%;
    margin-top: var(--p-m);
    flex-basis: auto;
    align-self: center;
  }
}

.registration-student {
  padding: var(--p-xl) 0;
}

.registration-student__container {
  padding-bottom: var(--p-m);
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

@media only screen and (max-width: 48em) {
  .registration-student__container {
    flex-direction: column;
    align-items: flex-start;
  }
}

.registration-student__container div {
  padding-right: var(--p-m);
  flex: 1;
}

@media only screen and (max-width: 48em) {
  .registration-student__container div {
    padding: 0;
  }
}

.registration-student__container a {
  flex-basis: 41rem;
}

@media only screen and (max-width: 48em) {
  .registration-student__container a {
    width: 80%;
    margin-top: var(--p-m);
    flex-basis: auto;
    align-self: center;
  }
}

.tasks {
  padding: var(--p-xl) 0;
}

.tasks__container {
  gap: var(--p-m);
  padding: var(--p-m) 0;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

@media only screen and (max-width: 48em) {
  .tasks__container {
    flex-direction: column;
    display: flex;
  }
}

@media only screen and (max-width: 48em) {
  .tasks__container a {
    width: 80%;
    align-self: center;
  }
}

.about {
  background-color: var(--bg-light);
  padding: var(--p-xl) 0;
}

.about__container {
  gap: var(--p-m);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media only screen and (max-width: 48em) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.about__seemore {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 48em) {
  .about__seemore {
    margin-top: 1rem;
  }
}

.about__seemore h3 {
  margin-bottom: var(--p-sm);
  align-self: flex-start;
}

@media only screen and (max-width: 48em) {
  .about__seemore h3 {
    margin-top: var(--p-m);
  }
}

.about__seemore a {
  width: 100%;
  height: auto;
  text-align: center;
  display: block;
}

@media only screen and (max-width: 48em) {
  .about__seemore a {
    text-align: left;
  }
}

.about__seemore a:hover svg {
  transform: scale(1.05);
}

.about__seemore svg {
  max-width: 80%;
  transition: transform .35s ease-in;
}

.contact {
  background-color: var(--bg-dark);
  padding: var(--p-xl) 0;
}

.contact h2, .contact p, .contact a {
  --primary: #009fe3;
  --primary-hover: #00b3ff;
  color: var(--text-secondary);
}

.contact__grid {
  gap: var(--p-m);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

@media only screen and (max-width: 48em) {
  .contact__grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.footer {
  padding: var(--p-lg) 0;
  background-color: var(--bg-white);
}

.image_gallery {
  margin: 10px 50px;
}

.image_gallery img {
  width: 400px;
  padding: 5px;
  transition: all 1s;
}

.image_gallery img:hover {
  transform: scale(1.1);
}

.single_image_display {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.image_content {
  max-width: 80%;
  max-height: 80%;
  margin: auto;
  display: block;
}

.close {
  color: #fff;
  cursor: pointer;
  font-size: 50px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  right: 30px;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: 0;
}

.flickity-viewport {
  height: 100%;
  touch-action: pan-y;
  position: relative;
  overflow: hidden;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-slider {
  left: unset;
  right: 0;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

.flickity-cell {
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-cell {
  left: unset;
  right: 0;
}

.flickity-button {
  color: #333;
  background: rgba(255, 255, 255, .75);
  border: none;
  position: absolute;
}

.flickity-button:hover {
  cursor: pointer;
  background: #fff;
}

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: .6;
}

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-prev-next-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  left: 10px;
  right: auto;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flickity-page-dots {
  width: 100%;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: -25px;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dot {
  width: 10px;
  height: 10px;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-indent: -9999px;
  background: rgba(51, 51, 51, .25);
  border: none;
  border-radius: 50%;
  margin: 0 8px;
  padding: 0;
  display: block;
  overflow: hidden;
}

.flickity-rtl .flickity-page-dot {
  text-indent: 9999px;
}

.flickity-page-dot:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-page-dot.is-selected {
  background: #333;
}

/*# sourceMappingURL=index.761ce9b4.css.map */
